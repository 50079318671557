import Swal from "sweetalert2";

export function defaultSuccessMessage(message: string) {
	Swal.fire({
		position: "top",
		icon: "success",
		title: message,
		showConfirmButton: false,
		timer: 3000,
		toast: true,
	});
}

export function defaultErrorMessage(message: string) {
	Swal.fire({
		icon: "error",
		title: "Oops...",
		text: message,
	});
}
